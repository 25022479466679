import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Modal,
  Button,
  Container,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import CoffeeDetail from '../../components/coffee-detail/index.ts';
import ImageGallery from '../../components/image-gallery/index.ts';
import FavoriteButton from '../../components/favorite-button/index.ts';
import ProSettings from '../../components/pro-settings/index.ts';

import { useAuth } from '../../context/Auth.tsx';
import { fetchFeedDetail } from '../../services/api.ts';

import './style.css';

function useFetchData(feedId) {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { authToken } = useAuth();
  useEffect(() => {
    setLoading(true);
    fetchFeedDetail(authToken, feedId)
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.json())
      .then((body) => {
        setLoading(false);
        setData(body);
      })
      .catch(() => {
        console.log('error!'); // TODO
      });
  }, [authToken, feedId]);
  return {
    isLoading,
    data,
  };
}

const FeedDetail = () => {
  const { feedId } = useParams();
  const { isLoading, data } = useFetchData(feedId);
  const history = useHistory();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const {
    coffeeName,
    coffeeId,
    coffeeRating,
    averageRating,
    comment,
    imageUrl,
    productImageUrl,
    brandImageUrl,
    brandName,
    brandId,
    coffeeBrewMethod,
    proSettings,
  } = data;

  const images = [];
  if (imageUrl) {
    images.push(imageUrl);
  }
  if (productImageUrl) {
    images.push(productImageUrl);
  }
  if (brandImageUrl) {
    images.push(brandImageUrl);
  }

  return (
    <Modal show fullscreen>
      <Modal.Header>
        <Modal.Title>
          <Button variant="outline-primary" aria-label="Back" onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faArrowLeft} />
            &nbsp;
            <span>{coffeeName}</span>
          </Button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="feedDetail">
        <CoffeeDetail
          coffeeName={coffeeName}
          coffeeLink={coffeeId ? `/coffee/${coffeeId}` : undefined}
          brandName={brandName}
          brandLink={brandId ? `/brand/${brandId}` : undefined}
          brewMethod={coffeeBrewMethod}
          coffeeRating={coffeeRating}
          averageRating={averageRating}
          comment={comment}
        />
        <Container className="proSettingsContainer">
          { proSettings && <ProSettings data={proSettings} />}
          { !proSettings && <span>(no PRO settings)</span>}
        </Container>
        <Container className="imageContainer">
          { images && (
          <ImageGallery images={images} />
          )}
        </Container>
        <Container className="button">
          <FavoriteButton
            feedId={parseInt(feedId, 10)}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default FeedDetail;
