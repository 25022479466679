import React from 'react';
import { Route, BrowserRouter as Router, Redirect } from 'react-router-dom';

import FeedOverview from './pages/feed-overview/index.ts';
import FeedDetail from './pages/feed-detail';
import BrandDetail from './pages/brand-detail/index.ts';
import Signup from './pages/signup';
import Search from './pages/search/index.ts';
import AdminHome from './pages/admin-home';
import FocusPage from './pages/focus-page/index.ts';
import ProSettingsPage from './pages/pro-settings-page/index.ts';
import CoffeePage from './pages/coffee-page/index.ts';

import Login from './components/Login.tsx';
import Logout from './components/Logout';
import NewCoffee from './components/new-coffee';
import CheckIn from './components/check-in';
import Experiments from './pages/experiments/index.ts';

import PrivateRoute from './PrivateRoute';
import { AuthProvider } from './context/Auth.tsx';
import { StoreProvider } from './store/store-context.tsx';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const App = () => (
  <div className="App">
    <header>
      <AuthProvider>
        <StoreProvider>
          <Router basename="/#app">
            <PrivateRoute exact path="/" component={FeedOverview} />
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <Route path="/detail/:feedId" component={FeedDetail} />
            <Route path="/brand/:brandId" component={BrandDetail} />
            <Route path="/coffee/:coffeeId" component={CoffeePage} />
            <Route path="/signup" component={Signup} />
            <Route path="/new-coffee" component={NewCoffee} />
            <Route path="/check-in" component={CheckIn} />
            <Route path="/search" component={Search} />
            <Route path="/focus" component={FocusPage} />
            <Route path="/pro-settings" component={ProSettingsPage} />
            <Route path="/admin">
              <Route path="/" component={AdminHome} />
            </Route>
            <Route path="/xp" component={Experiments} />
            <Redirect to="/" />
          </Router>
        </StoreProvider>
      </AuthProvider>
    </header>
  </div>
);

export default App;
